import { PostOrPage, Tag } from '@tryghost/content-api'
import type { DefaultSeoProps } from 'next-seo'
export const defaultSeo: DefaultSeoProps = {
  titleTemplate: '%s | Swift Cloud',
  defaultTitle: 'Swift Cloud',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://swift.cloud',
    site_name: 'Swift Cloud',
    images: [
      {
        url: 'https://swift-cloud.ghost.io/content/images/2022/07/swift-cloud.png',
        width: 1600,
        height: 900,
        alt: 'Swift Cloud',
      }
    ]
  },
  twitter: {
    site: '@SwiftCloudApp',
    cardType: 'summary_large_image',
  }
}
export function postOpenGraphData (post: PostOrPage) {
  function tags (tags: Tag[] | undefined) {
    if (!tags) return []
    return tags.map(tag => tag.name as string)
  }
  return {
    title: post.title as string,
    description: post.excerpt as string,
    url: `https://swift.cloud/blog/${post.slug}`,
    type: 'article',
    article: {
      publishedTime: post.published_at as string,
      modifiedTime: post.updated_at as string,
      section: post.primary_tag?.name as string,
      authors: [
        `https://swift.cloud/blog/author/${post.primary_author?.slug}`
      ],
      tags: tags(post.tags),
    },
    images: [
      {
        url: post.og_image as string,
        width: 1600,
        height: 900,
        alt: post.title as string,
      },
    ],
  }
}
export function postJsonLd (post: PostOrPage) {
  return {
    url: `https://swift.cloud/blog/${post.slug}`,
    title: post.title as string,
    images: [post.feature_image as string],
    datePublished: post.published_at as string,
    dateModified: post.updated_at as string,
    authorName: post.primary_author?.name as string,
    publisherName: 'Swift Cloud',
    publisherLogo: 'https://swift.cloud/logo.png',
    description: post.excerpt as string
  }
}