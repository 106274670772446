import type { AppProps } from 'next/app'
import Script from 'next/script'
import { DefaultSeo } from 'next-seo'

import '@styles/globals.css'
import { gaId } from '@src/lib/env'
import { useAnalytics } from '@src/lib/analytics'
import { defaultSeo } from '@src/lib/seo'

function SwiftCloud({ Component, pageProps }: AppProps) {
  useAnalytics()
  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy='afterInteractive'
        src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        onLoad={() => {
          window.dataLayer = window.dataLayer || []
          window.gtag = function () {
            window.dataLayer.push(arguments)
          }

          window.gtag('js', new Date())
          window.gtag('config', gaId, {
            page_path: window.location.pathname
          })
        }}
      />
      <DefaultSeo {...defaultSeo} />
      <Component {...pageProps} />
    </>
  )
}

export default SwiftCloud
